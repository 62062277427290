// 工单管理
import request from '@http';

// 2022/04/24 王江毅 工单列表
export function getWordList(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/word/list',
        params
    })
}

// 2022/06/10 王江毅 工单导出
export function wordExport(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/word/export',
        params,
        responseType: 'blob',
    })
}

// 2022/04/25 王江毅 汇款
export function wordAudit(data) {
    return request({
        method: 'PUT',
        url: '/api/gzf/word/audit',
        data
    })
}

// 2022/04/25 王江毅 汇款
export function getToContratAmount(params) {
    return request({
        method: 'get',
        url: `/api/gzf/word/getToContratAmount`,
        params
    })
}

// 2022/06/30 王江毅 汇款
export function getWordInfo(uuid) {
    return request({
        method: 'get',
        url: `/api/gzf/word/info/${uuid}`,
    });
}

// 2022/06/30 王江毅 工单处理
export function wordDispose(data) {
    return request({
        method: 'PUT',
        url: '/api/gzf/word/checkout/dispose',
        data
    })
}

// 2022/06/30 王江毅 获取换房数据
export function getRenewalRoomChangeInfo(params) {
    return request({
        method: 'get',
        url: `/api/gzf/renewal/room/change/info`,
        params
    })
}

// 2022/06/30 王江毅 换房确认
export function renewalRoomChangeIsCanAudit(params) {
    return request({
        method: 'get',
        url: `/api/gzf/renewal/room/change/isCanAudit`,
        params
    });
}

// 2022/06/30 王江毅 指定换房确认人
export function renewalRoomChangeLeasingAudit(params) {
    return request({
        method: 'get',
        url: `/api/gzf/renewal/room/change/leasingAudit`,
        params
    });
}

// 2022/06/30 王江毅 换房新签
export function renewalRoomChangeNewContractSign(params) {
    return request({
        method: 'get',
        url: `/api/gzf/renewal/room/change/newContract/sign`,
        params
    });
}